type NamespaceDictionary = {
  [domain: string]: string[]
}

const NAMESPACES = {
  thermostat: ['thermostatrewards.com', 'program.enrollmythermostat.com'],
  charging: ['chargingrewards.com'],
} as NamespaceDictionary

const isBrowser = typeof window !== 'undefined'

const domainPrefix = (domain: string) => {
  const sanitizedDomain = (domain || 'Thermostat').toLowerCase()
  if (
    NAMESPACES[sanitizedDomain] &&
    isBrowser &&
    NAMESPACES[sanitizedDomain].filter((d) =>
      window.location.hostname.endsWith(d)
    ).length > 0
  ) {
    return ''
  }
  return `/${sanitizedDomain}`
}

const constructQueryPath = (path: string, params: URLSearchParams) => {
  const queryString = params.toString()
  return `${path}${queryString.length > 0 ? '?' : ''}${queryString}`
}

export const landingPath = ({
  domain,
  partner,
  program,
  path,
  displayForm,
}: {
  domain: string
  partner: string
  program: string
  path?: string | null
  displayForm?: boolean
}) => {
  const params = new URLSearchParams()
  if (path) {
    params.append('path', path)
  }
  if (displayForm) {
    params.append('displayForm', '1')
  }

  return constructQueryPath(
    `${domainPrefix(domain)}/${partner}/${program}`,
    params
  )
}

export const nowPath = ({
  domain,
  partner,
  program,
}: {
  domain: string
  partner: string
  program: string
}) => {
  return `${domainPrefix(domain)}/${partner}/now/${program}`
}

export const formPath = ({
  domain,
  partner,
  program,
  path,
  signUpToken,
}: {
  domain: string
  partner: string
  program: string
  signUpToken: string
  path?: string | null
}) => {
  const params = new URLSearchParams()
  params.append('signUpToken', signUpToken)
  if (path) {
    params.append('path', path)
  }

  return constructQueryPath(
    `${domainPrefix(domain)}/${partner}/${program}/form`,
    params
  )
}

export const confirmationPath = ({
  domain,
  partner,
  program,
  signUpToken,
  path,
  displayDevices,
}: {
  domain: string
  partner: string
  program: string
  signUpToken: string
  path?: string | null
  displayDevices?: boolean
}) => {
  const params = new URLSearchParams()
  params.append('signUpToken', signUpToken)
  if (path) {
    params.append('path', path)
  }
  if (displayDevices) {
    params.append('displayDevices', '1')
  }

  return constructQueryPath(
    `${domainPrefix(domain)}/${partner}/${program}/confirmation`,
    params
  )
}

export const directoryPath = (domain: string, slug: string) => {
  return `${domainPrefix(domain)}/${slug}`
}

export const faqPath = (domain: string, slug: string) => {
  return `${domainPrefix(domain)}/${slug}/faq`
}
